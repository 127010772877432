<template>
  <b-container>
    <Form :editing="true">
      <!-- <template v-slot="{ validateState, isBusy, model }">
        <br />
        <h6>Edição de fluxo</h6>
        <br />
        <b-table :items="model.fluxo" :fields="fields" ref="fluxo-table">
          <template #cell(ordem)="{ index }"> {{ index + 1 }}</template>
          <template #cell(processo)="{ index }">
            <GenericSelect
              :name="'tipoProcesso' + index"
              labelKey="nome"
              idKey="id_tipo_processo"
              v-model="model.fluxo[index].id_tipo_processo"
              :route="tipoProcessoRoute"
              :disabled="isBusy"
              v-validate="{ required: true }"
              :state="validateState('tipoProcesso')"
            ></GenericSelect>
          </template>
          <template #cell(consideracoes)="{ index }">
            <b-form-checkbox v-model="model.fluxo[index].ciclico">
              Este processo pode ser repetido?
            </b-form-checkbox>
            <b-form-checkbox v-model="model.fluxo[index].fim">
              Este processo é final?
            </b-form-checkbox>
            <b-form-checkbox v-model="model.fluxo[index].opcional">
              Este processo é opcional?
            </b-form-checkbox>
          </template>
          <template #cell(opcional)="{ index }">
            <b-form-checkbox v-model="model.fluxo[index].opcional">
              Sim
            </b-form-checkbox>
          </template>
          <template #cell(excluir)="{ index }">
            <b-button
              class="btn btn-sm text--black"
              variant="outline-dark"
              @click="deleteRow(index, model)"
            >
              <trash-2-icon class="w20px" />
            </b-button>
          </template>
        </b-table>
        <b-button @click="addRow(model)"> Botão adicionar </b-button>
        <br />
      </template> -->
    </Form>
  </b-container>
</template>

<script>
// import GenericForm from '@/components/Form/GenericForm';
// import GenericSelect from '@/components/Form/GenericSelect';
import Form from './Form';

export default {
  components: {
    // GenericForm,
    // GenericSelect,
    Form,
  },

  data() {
    return {
      // fields: [
      //   { key: 'ordem', label: 'Ordem' },
      //   { key: 'processo', label: 'Processo' },
      //   { key: 'consideracoes', label: 'Considerações' },
      //   { key: 'excluir', label: 'Excluir' },
      // ],
      // fluxo: [],
      // tipoProcessoRoute: 'tipoProcesso',
    };
  },

  methods: {
    // deleteRow(index, model) {
    //   model.fluxo.splice(index, 1);

    //   for (let i = index; i < model.fluxo.length; i++) {
    //     model.fluxo[i].ordem -= 1;
    //   }
    // },

    // addRow(model) {
    //   model.fluxo.push({
    //     // Alterar lógica para ordem
    //     ordem: model.fluxo.length + 1,
    //     id_tipo_processo: null,
    //     fim: false,
    //     opcional: false,
    //     ciclico: false,
    //   });
    // },
  },
};
</script>
